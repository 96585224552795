@use "../../../variables.scss" as *;
@use "sass:math";

#tasks-grid {
  min-height: 300px;
}

.planning-grid {
  border-top: 1px solid var(--border-color);
}

.dx-datagrid-header-panel {
  padding-top: 0;
}

.dx-datagrid-header-panel .dx-datagrid-toolbar-button .dx-icon.dx-icon-plus,
.dx-datagrid-header-panel .dx-datagrid-toolbar-button .dx-button-text {
  color: #fff;
}

.dx-row.completed {
  background: var(--background-gray-color);
}

.edit-cell {
  position: relative;

  .priority-task,
  .status-task {
    position: absolute;
    margin-top: 10px;
    margin-left: 11px;
  }
}
