@use "../../../variables.scss" as *;

.pictured-item-select-box-dropdown,
.pictured-item-select-box {
  .pictured-item-image {
    flex: 0 0 2em;
    width: 2em;
    height: 2em;
    border: 1px solid var(--border-color);
    border-radius: 50%;
    margin-right: 8px;
    object-fit: cover;
    object-position: top;
  }
}

.pictured-item-select-box-dropdown .dx-list-item-content,
.pictured-item-select-field {
  display: flex;
  align-items: center;
}

.pictured-item-select-box .pictured-item-select-field {
  input[pictureditemeditorinput].dx-texteditor-input {
    color: var(--base-text-color);
    padding-left: 0;
  }

  .pictured-item-image {
    margin-left: var(--list-padding-left);
  }
}
