@use "../../../variables.scss" as *;

.create-account-form {
  .policy-info {
    color: var(--base-text-color-alpha);
    font-size: 12px;
    font-style: normal;

    a {
      color: var(--base-text-color-alpha);
    }
  }

  .login-link {
    color: var(--accent-color);
    font-size: 12px;
    text-align: center;
    padding: 6px 0 32px 0;
    border-bottom: 1px solid var(--border-color);
  }
}
