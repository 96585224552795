@use "../../../variables.scss" as *;

.form-photo-view {
  position: relative;
  display: flex;

  .form-photo {
    border-radius: 8px;
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid var(--border-color);
  }
}

.form-photo-view:hover {
  .editable {
    &::before,
    .edit-icon {
      opacity: 1;
      transition: opacity 400ms;
      border-radius: 50%;
    }
  }
}

.editable {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &::before {
    content: '';
    opacity: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .edit-icon {
    opacity: 0;
    display: block;
    position: absolute;
    color: white;
    font-size: 28px;
  }
}
