@use "../../../variables.scss" as *;

:root {
  --low-priorety-color: #dfb32f;
  --normal-priorety-color: #6fbaca;
  --high-priorety-color: #d9534f;
}

@mixin priority($priorety-color) {
  .card-priority {
    background: $priorety-color;
  }

  span.priority {
    color: $priorety-color;
  }
}

.kanban-card {
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
}

.card-priority {
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: 10px;
  width: 4px;
}

.card-wrapper {
  padding: 10px;

  .edit-button {
    position: absolute;
    right: 10px;
    font-size: 18px;
    opacity: 0.87;
  }

  &.priority-low {
    @include priority(var(--low-priorety-color));
  }

  &.priority-normal {
    @include priority(var(--normal-priorety-color));
  }

  &.priority-high {
    @include priority(var(--high-priorety-color));
  }
}

.card-content {
  padding-left: 14px;

  span.priority {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }

  span.date {
    padding-left: 8px;
    font-size: 12px;
    line-height: 14px;
    opacity: 0.87;
  }
}

.card-subject {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding-right: 40px;
}

.card-assignee {
  padding-top: 14px;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
