@use "../../../variables.scss" as *;
@use "sass:math";

.status-contact {
  span {
    font-size: 13px;

    &::before {
      --diameter: 10px;

      content: " ";
      width: var(--diameter);
      height: var(--diameter);
      border-radius: calc(var(--diameter) / 2);
      margin-right: calc(var(--diameter) / 2);
      display: inline-block;
    }

    &.input {
      display: block;
      padding: 15px 16px 14px;
    }
  }
}
