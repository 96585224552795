@use "../../../variables.scss" as *;

.card-tasks {
  min-height: 300px;
  padding: 20px 0 0;
}

.tasks-grid {
  border-top: 1px solid var(--border-color);
}
